import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import "react-slideshow-image/dist/styles.css";
import { getPage } from "../../api/Api";
import Loading from "../Loading";
import { Cell, Grid, Row } from "../FoundationGrid";
import Section from "../Section";

function DefaultPage({ identifier, locale }) {
  const [page, setPage] = useState({ identifier: identifier, locale: locale });

  const init = () => {
    const result = getPage(page.identifier, page.locale);
    result.then(({ status, data }) => {
      if (status === 200) {
        setPage({ ...page, data });
      }
    });
  };

  useEffect(init, []);

  if (!page.data) return <Loading page={page} fullscreen />;

  const { data } = page;

  return (
    <div className={`page default-page page-${page.identifier}`}>
      <MainLayout page={page}>
        <Grid>
          <Row>
            <Cell sm={24}>
              <Section small>
                {data.PageContentHtml ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: data.PageContentHtml }}
                  />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: data.PageContent }} />
                )}
              </Section>
            </Cell>
          </Row>
        </Grid>
      </MainLayout>
    </div>
  );
}

export default DefaultPage;
